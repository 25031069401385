import React, { PropsWithChildren } from "react";

import * as styles from "./index.module.scss";
import { SEO, MetaProps } from "~components/templates/seo";
import { AssessmentLp2PaneHeader } from "../lp-header";
import { AssessmentLp2PaneFooter } from "../lp-footer";
import { Header } from "~components/templates/header";
import { Footer } from "~components/templates/footer";
import { PipeDriveForm } from "~components/utils/pipedrive-form";

type Props = {
  formId: string;
  meta?: MetaProps;
};

const ALTERNATIVE_GOOGLEFORM_URL = "https://forms.gle/B7c1hGh9i4rmJnEg6";

export const Assessment2PaneLayout = ({
  children,
  formId,
  meta,
}: PropsWithChildren<Props>) => {
  return (
    <>
      {meta && (
        <SEO
          title={meta.title}
          description={meta.description}
          image={meta.image}
          lang={meta.lang}
          meta={meta.meta}
        />
      )}
      <Header defaultTheme="TwoPane" useTransition={false} />

      <div className={styles.AssessmentCommon2PaneContent}>{children}</div>
      <div className={styles.AssessmentCommon2PaneContact}>
        <div className={styles.AssessmentCommon2PaneContact_Container}>
          <PipeDriveForm formID={formId} />
          <p className={styles.AssessmentCommon2PaneContact_Escape}>
            Internet
            Explorerをお使いの場合など、お問い合わせフォームが表示されない場合は
            <a
              className={styles.AssessmentCommon2PaneContact_EscapeLink}
              href={ALTERNATIVE_GOOGLEFORM_URL}
            >
              別ページ
            </a>
            からお問い合わせください。
          </p>
        </div>
      </div>
      <div className={styles.AssessmentCommon2PaneFooter}>
        <Footer />
      </div>
    </>
  );
};
