import React, { FC } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import * as styles from "./index.module.scss";

import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";

export const VoicesLinkFooter: FC = () => {
  const data: GatsbyVoicesLinkFooterComponentQuery = useStaticQuery(graphql`
    query VoicesLinkFooterComponent {
      assessment_banner: file(
        relativePath: { eq: "assessment/assessment_banner.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      voices_banner: file(
        relativePath: { eq: "assessment/voices_banner.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <div className={styles.VoiceLinkFooter_LeadToWrapper}>
      <Common2PaneSectionLayout>
        <div className={styles.VoiceLinkFooter_BannerWrapper}>
          <Link to="/assessment" className={styles.VoiceLinkFooter_LeadTo}>
            <OptionalGatsbyImage
              className={styles.VoiceLinkFooter_AssessmentBanner}
              data={data.assessment_banner}
              alt="セキュリティ診断サービス詳細はこちら"
            />
          </Link>
          <Link
            to="/assessment/voice"
            className={styles.VoiceLinkFooter_LeadTo}
          >
            <OptionalGatsbyImage
              className={styles.VoiceLinkFooter_AssessmentBanner}
              data={data.voices_banner}
              alt="全ての事例を見る"
            />
          </Link>
        </div>
      </Common2PaneSectionLayout>
    </div>
  );
};
