// extracted by mini-css-extract-plugin
export var Contact = "index-module--Contact--H4k3l";
export var Contact_Button = "index-module--Contact_Button--iC038";
export var Contact_H1 = "index-module--Contact_H1--8sPke";
export var Contact_H5 = "index-module--Contact_H5--9Rwqk";
export var Contact_Sp = "index-module--Contact_Sp--5OUdX";
export var Contact_ToPrivacyPolicy = "index-module--Contact_ToPrivacyPolicy--i1SFc";
export var Contact_Wrapper = "index-module--Contact_Wrapper--SDHPE";
export var Mixin_Textbox = "index-module--Mixin_Textbox--l3o8A";
export var Voice = "index-module--Voice--KVpVM";
export var Voice_Contact = "index-module--Voice_Contact--aq-8t";
export var Voice_ContactEscape = "index-module--Voice_ContactEscape--TO4na";
export var Voice_ContactEscapeLink = "index-module--Voice_ContactEscapeLink--K-i7P";
export var Voice_ContentWrapper = "index-module--Voice_ContentWrapper--ZYKsE";
export var Voice_Description = "index-module--Voice_Description--+e1Yb";
export var Voice_ShareButtonWrapper = "index-module--Voice_ShareButtonWrapper--opQkG";
export var Voice_ThumbnailAvatar = "index-module--Voice_ThumbnailAvatar--edg65";
export var Voice_Title = "index-module--Voice_Title--F5QFx";
export var Voice_TitleWrapper = "index-module--Voice_TitleWrapper--krDKg";