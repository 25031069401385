import type { Transformer } from "unified";

export const h6toFigcaptionPlugin = () => {
  const transformer: Transformer = (node, vfile) => {
    visit(
      (targetNode) => {
        const children = targetNode.children as any[];
        if (children === undefined) return;

        const transformed = children.reduce((prev, child) => {
          if (child.type === "element" && child.tagName === "h6") {
            return [
              ...prev,
              {
                ...child,
                tagName: "figcaption",
              },
            ];
          } else {
            return [...prev, child];
          }
        }, []);

        targetNode.children = transformed;
      },
      node,
      vfile,
    );
  };
  return transformer;
};

/**
 * 画像の次にfigcaptionを挿入する
 * 引用内にあるリンクを全てciteに変換する
 */
export const captionPlugin = () => {
  const transformer: Transformer = (node, vfile) => {
    visit(
      (targetNode) => {
        const children = targetNode.children as any[];
        if (children === undefined) return;

        const transformed = children.reduce((prev, child) => {
          if (child.type === "element" && child.tagName === "img") {
            return [
              ...prev,
              child,
              {
                tagName: "figcaption",
                type: "element",
                children: [
                  {
                    type: "text",
                    value: child.properties.alt,
                  },
                ],
              },
            ];
          } else if (
            child.type === "element" &&
            child.tagName === "blockquote"
          ) {
            const citeNodes: any[] = [];
            visit(
              (targetNode) => {
                const children = targetNode.children as any[];
                if (children === undefined) return;

                const transformed = children.reduce((prev, child) => {
                  if (child.type === "element" && child.tagName === "a") {
                    citeNodes.push({
                      tagName: "cite",
                      type: "element",
                      children: [child],
                    });
                    return [...prev];
                  } else {
                    return [...prev, child];
                  }
                }, []);
                targetNode.children = transformed;
              },
              child,
              vfile,
            );

            return [...prev, child, ...citeNodes];
          } else {
            return [...prev, child];
          }
        }, []);

        targetNode.children = transformed;
      },
      node,
      vfile,
    );
  };
  return transformer;
};

const visit = (
  transformer: Transformer,
  ...params: Parameters<Transformer>
) => {
  const [node, vfile, next] = params;
  if (transformer(node, vfile, next)) {
    return;
  }
  if (node.children === undefined) {
    return;
  }
  const children = node.children as any[];

  for (let i = 0; i < children.length; i++) {
    visit(transformer, children[i], vfile);
  }
};
